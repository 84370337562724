<template>
  <table style="width: 100%; height: 100%">
    <tr>
      <td style="text-align: center; vertical-align: middle;">
        <div class="login-container">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            auto-complete="on"
            label-position="left"
          >
            <div class="title-container">
              <h3 class="title">
                {{ $t('login.title') }}
              </h3>
              <lang-select v-if="multilingual" class="set-language" />
            </div>

            <el-form-item prop="code">
              <span class="svg-container">
                <svg-icon icon-class="user" />
              </span>
              <el-input
                v-model="loginForm.code"
                :placeholder="$t('login.code')"
                name="code"
                type="text"
                auto-complete="on"
                @keyup.enter.native="handleLogin"
              />
            </el-form-item>

            <el-form-item prop="password">
              <span class="svg-container">
                <svg-icon icon-class="password" />
              </span>
              <el-input
                v-model="loginForm.password"
                :type="passwordType"
                :placeholder="$t('login.password')"
                name="password"
                auto-complete="on"
                @keyup.enter.native="handleLogin"
              />
              <span class="show-pwd" @click="showPwd">
                <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
              </span>
            </el-form-item>

            <el-button
              :loading="loading"
              type="primary"
              style="width:100%;margin-bottom:30px;"
              @click.native.prevent="handleLogin"
            >
              {{ $t('login.logIn') }}
            </el-button>

            <el-collapse-transition>
              <el-alert v-if="errorMsg" :title="errorMsg" type="error" :closable="false" />
            </el-collapse-transition>
            <div class="footer">
              <router-link :to="{ name: 'forgot-password' }">
                <span>{{ $t('forgotPassword.title') }}</span>
              </router-link>
            </div>
          </el-form>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import LangSelect from '@/components/LangSelect';
import { focusFirstInputInEl } from '@/utils/';

const customHandledHTTPErrorCodes = [401, 403];

export default {
  name: 'Login',
  components: {
    LangSelect,
    SvgIcon
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$i18n.t('validation.required')));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$i18n.t('validation.required')));
      } else {
        callback();
      }
    };
    return {
      errorMsg: '',
      loading: false,
      loginForm: {
        code: '',
        password: ''
      },
      loginRules: {
        code: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername
          }
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword
          }
        ]
      },
      passwordType: 'password',
      redirect: undefined,
      showDialog: false
    };
  },
  computed: {
    multilingual() {
      return this.$store.getters['multilingual'];
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    },
    loading: function(val) {
      if (val) {
        this.errorMsg = '';
      }
    }
  },
  created() {
    customHandledHTTPErrorCodes.forEach(code => {
      this.$store.dispatch('notify/disableErrorHTTPCode', code);
    });
  },
  mounted() {
    focusFirstInputInEl(this.$el);
  },
  destroyed() {
    customHandledHTTPErrorCodes.forEach(code => {
      this.$store.dispatch('notify/enableErrorHTTPCode', code);
    });
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch('loginByUsername', {
              code: this.loginForm.code.trim(),
              password: this.loginForm.password
            })
            .then(() => {
              this.loading = false;
              this.$router.push({
                path: this.redirect || '/'
              });
            })
            .catch(reason => {
              if (reason.response) {
                switch (reason.response.status) {
                  case 401:
                    this.errorMsg = this.$i18n.t('login.unauthorised');
                    break;
                  case 403:
                    this.errorMsg = this.$i18n.t('login.forbidden');
                    break;
                  default:
                    this.errorMsg = reason.response.statusText;
                }
              } else {
                switch (reason.message.toLowerCase()) {
                  case 'network error':
                    this.errorMsg = this.$i18n.t('request.networkError');
                    break;
                  default:
                    this.errorMsg = reason.name + ': ' + reason.message;
                    break;
                }
              }
              this.loading = false;
            });
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    }
  }
};
</script>

<style src="@/styles/components/login/input.scss" lang="scss"></style>

<style src="@/styles/components/login/index.scss" lang="scss" scoped></style>
